import "./convenios.css";
import Silder from "react-slick";
import ConveniosData from "../convenios";

const Convenios = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="container-convenios">
      <div className="container-slider-section">
           <Silder {...settings}>
        {ConveniosData.map((conv) => {
          return (
            <div>
            <div className="container-primera-seccion">
              <div className="convenios-images">
                <img src={conv.img} alt="" />
              </div>
              <div className="container-descripcion-militares">
                <h1>{conv.title}</h1>
                {/* <p>{conv.description}</p> */}
              </div>
            </div>
            </div>
          );
        })}
      </Silder>
      </div>
    </div>
  );
};

export default Convenios;
