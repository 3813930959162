import "./psicomotricidad.css"

const Psicomotricidad = () => {
    return(
        <div className="container-psicomotricidad">
            <div className="section-psicomotricidad">
                <h1>PSICOMOTRICIDAD</h1>
                <div className="container-img-description-psicomotricidad">
                    <img src="../assets/psicomotricidad.png" alt="" />
                    <div className="container-psicomotricidad-description">
                        <h2>Descripción</h2>
                        <ul>
                            <li>Permite comprender y dar sentido al mundo interior del niño observando e interviniendo a través de sus acciones, sus juegos y la forma de relacionarse con los otros y el entorno.</li>
                            <li>Teniendo en cuenta la interrelación que existe entre conocimiento, movimiento y emociones busca recuperar y potenciar las habilidades y destrezas, la capacidad sensitiva, perceptiva, representativa y conunicativa.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Psicomotricidad