const ConveniosData = [
    {
        title : "AYEX.",
        img: "../assets/bps.svg",
        description:"Funcionarios y familiares de funcionarios policiales."
    },
    {   
        title : "Tutela social.",
        img: "../assets/logo-militares.png",
        description:"Funcionarios y familiares de funcionarios que trabajen en las Fuerzas Armadas."
    },
    {
        title : "Tutela policial.",
        img: "../assets/logo-policial.png",
        description:"Funcionarios y familiares de funcionarios policiales."
    },
 ]

 export default ConveniosData