import "./header.css";

const Header = () => {
  const setMenuStatus = () => {
    document
      .querySelector(".container-hamburger-group")
      .classList.toggle("active");
      document
      .querySelector(".header-group")
      .classList.toggle("active");
  };


  return (
    <>
      <header style={{backgroundColor: window.location.pathname !== "/" ? "#FFF8E6" : "none"}} className="header-scroll">
      <nav className="container-nav">
        <div className="contenedor-logos">
          <div className="container-logo-header-arbol"></div>
          <div className="container-logo-header"></div>
          <div className="container-hamburguer-header" onClick={setMenuStatus}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M4.5 25.5H31.5M4.5 18H31.5M4.5 10.5H31.5"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <ul className="header-group">
          <li>
            <a className="header-button" href="/">
              Home
            </a>
          </li>
          <li>
            <a className="header-button" href="#container-nosotros">
              Nosotros
            </a>
          </li>
          <li>
            <a className="header-button" href="#container-nosotros">
              Servicios
            </a>
          </li>
          <li>
            <a className="header-button" href="#container-nosotros">
              Convenios
            </a>
          </li>
          <li>
            <a className="header-button" href="#container-nosotros">
              Contacto
            </a>
          </li>
        </ul>

      </nav>
        <div className="container-hamburger-menu">
          <div className="container-hamburger-group">
            <ul className="hamburger-header-group">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href=".container-nosotros">Contacto</a>
              </li>
              <li>
                <a href="#container-nosotros">Nosotros</a>
              </li>
              <li>
                <a href="#container-nosotros">Servicios</a>
              </li>
            </ul>
          </div>
        </div>
    </header>
    </>
  );
};

export default Header;
