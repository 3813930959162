import "./conocenos.css";
import {useInView} from 'react-intersection-observer'


const Conocenos = () => {

  const [ref, inView] = useInView();

  if(inView) {
    setTimeout(() => {
      document.querySelector('.container-conocenos-section').classList.add('active-img')
    }, 700);
  }

  return (
    <div className="container-conocenos">
      <div className="container-conocenos-section"  ref={ref}>
        <h1 className="global-title ">Quienes somos</h1>
        <div className="container-conocenos-img-description">
          <div className="container-conocenos-description">
          <p>
            Conformamos un equipo integrado por profesionales del área de la
            salud y la educación, el cual tiene como objetivo acompañar las
            dificultades en el desarrollo de niños, niñas y adolescentes.
          </p>
          <p>
            Brindamos atención en las áreas de psicología, fonoaudiología,
            pedagogía especializada y psicomotricidad, interviniendo desde evaluaciones
            diagnósticas y tratamientos.
          </p>
          <p>
            Priorizamos un abordaje desde la individualidad del consultante, en
            continuo diálogo e intercambio con las familias, las instituciones
            educativas y los equipos de salud, conformando una red de contención
            que posibilite acompañar el proceso de crecimiento de quien
            consulta.
          </p>
          <p>Consideramos que el trabajo interdisciplinario nos identifica, mediante el mismo potenciamos el saber de cada área, abordando los diferentes casos que llegan a consulta desde una mirada integral, profesional y comprometida.</p>
        </div>
          <div className="container-img-conocenos">
            <img src="../assets/quienes-somos.jpeg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conocenos;
