const sectionInformation = [
    {
        img: "../assets/psicologia.png",
        titulo: "Psicología",
        subtitulo: "Atención personalizada",
        url: "Psicologia",
    },
    {
        img: "../assets/pedagogia.png",
        titulo: "Pedagogía",
        subtitulo: "Atención personalizada",
        url: "Pedagogia",
    },
    {
        img: "../assets/fonoaudiologia.png",
        titulo: "Fonoaudiología",
        subtitulo: "Atención personalizada",
        url: "Fonoaudiologia",

    },
    {
        img: "../assets/psicomotricidad.png",
        titulo: "Psicomotricidad",
        subtitulo: "Atención personalizada",
        url: "Psicomotricidad",
    }
]

export default sectionInformation