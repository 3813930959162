import "./psicologia.css"

const Psicologia = () => {
    return(
        <div className="container-psicologia">
            <div className="section-psicologia">
                <h1>ATENCIÓN PSICOLOGICA</h1>
                <div className="container-img-description-psicologia">
                    <img src="../assets/img-slider1.jpg" alt="" />
                    <div className="container-psicologia-description">
                        <h2>Descripción</h2>
                        <ul>
                            <li>La psicología clínica en el ámbito infantil y adolescente trabaja de formas diversas, considerando siempre la singularidad e historia específica de quien llega a la consulta.</li>
                            <li>Pensamos el espacio psicoterapéutico como aquel lugar donde se intenta recorrer junto al paciente un camino que lo habilite a desplegar su mundo interno, sus deseos, temores y fantasías.</li>
                            <li>Y a partir de aquí, se ira realizando un proceso que le permita poco a poco ir pensando y resignificando su conflictiva</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Psicologia