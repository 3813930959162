const trabajadores = [
    {
        name: "Maria Jose Rey",
        titulo: "Lic. en Psicología"
    },
    {
        name: "Pamela De León",
        titulo: "Lic. en Psicomotricidad"
    },
    {
        name: "Adriana Tejera",
        titulo: "Maestra especializada"
    },
    {
        name: "Mariela Levy",
        titulo: "Lic. en Fonoaudiología"
    }
]

export default trabajadores